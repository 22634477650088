<template>
  <div class="wei">
    <div class="wei-box">
      <div class="all">
        <div class="all-head">
          <div
            class="all-head-list"
            v-for="(item, index) in allData"
            :key="index"
          >
            <div class="all-head-list-l">
              <div class="all-head-list-img">
                <img :src="item.img" alt="" />
              </div>
            </div>
            <div class="all-head-list-r">
              <div class="all-head-list-title">
                {{ item.name }}

                <el-popover
                  v-if="item.title"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="item.title"
                >
                  <i slot="reference" class="el-icon-question"></i>
                </el-popover>
              </div>
              <div class="all-head-list-num">
                <span style="font-size:15px;">￥</span>
                <span :id="'all-head-list-num-' + index">0.00</span>
              </div>
              <div class="all-head-list-btn" v-if="!index">
                <el-button
                  type="danger"
                  v-if="!bank.bank.card"
                  size="mini"
                  @click="dialogVisible = true"
                  >绑定账户</el-button
                >
                <el-button
                  type="primary"
                  v-if="bank.bank.card"
                  size="mini"
                  @click="dialogVisible1 = true"
                  >申请提现</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alls-head" :style="{ height: !tabIndex ? '130px' : '60px' }">
        <div class="alls-head-l">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.name"
              :name="item.name"
              v-for="(item, index) in tab"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <div class="alls-head-r" v-if="!tabIndex">
          <div class="alls-head-r-list" style="width:15%">
            <span>状态：</span>
            <el-select
              v-model="tab[tabIndex].status"
              placeholder="请选择订单状态"
            >
              <el-option label="全部" value="-1"></el-option>
              <el-option label="未付款" value="0"></el-option>
              <el-option label="待核销" value="1"></el-option>
              <el-option label="已完成" value="2"></el-option>
            </el-select>
          </div>
          <div class="alls-head-r-list" style="width:15%">
            <span>类型：</span>
            <el-select
              v-model="tab[tabIndex].order_type"
              placeholder="请选择订单类型"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="alls-head-r-list" style="width:35%">
            <span>时间：</span>
            <el-date-picker
              v-model="tab[tabIndex].time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '00:00:00']"
              value-format="yyyy-MM-dd"
              @change="clearDate"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <div class="alls-head-r-list" style="width:35%">
            <el-input
              placeholder="请输入内容"
              v-model="tab[tabIndex].key"
              class="input-with-select"
            >
              <el-select
                v-model="tab[tabIndex].value"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="全部" value="0"></el-option>
                <el-option label="订单单号" value="1"></el-option>
                <el-option label="电话号码" value="2"></el-option>
                <el-option label="员工小程序ID" value="3"></el-option>
              </el-select>
              <el-button
                slot="append"
                type="primary"
                icon="el-icon-search"
                @click="get_order"
              ></el-button>
            </el-input>
          </div>
          <!-- <el-button type="primary" style="margin-left:10px;" @click="search"
            >搜索</el-button
          > -->
        </div>
      </div>
      <div class="alls">
        <div v-if="!tabIndex">
          <el-table
            :data="tab[tabIndex].data"
            :border="$table('border')"
            :stripe="$table('stripe')"
            key="1"
            :header-cell-style="{ background: $table('bgcolor') }"
          >
            <el-table-column prop="code" label="订单编号"> </el-table-column>
            <el-table-column prop="date" label="商品信息">
              <template slot-scope="scope">
                <span
                  >{{
                    scope.row.goods_info ? scope.row.goods_info.name : "--"
                  }}
                  x {{ scope.row.goods_info.num || 1 }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="user" label="客户信息">
              <template slot-scope="scope">
                <span>{{
                  scope.row.user
                    ? scope.row.user.name + " (" + scope.row.user.phone + ")"
                    : "散客"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="交易金额"> </el-table-column>
            <!-- <el-table-column prop="date" label="交易门店"> </el-table-column> -->
            <!-- <el-table-column prop="date" label="支付方式"> </el-table-column> -->
            <el-table-column prop="updated_at" label="完成时间">
            </el-table-column>
            <!-- <el-table-column prop="user.p_user.name" label="推荐人">
              <template slot-scope="scope">{{
                scope.row.user.p_user ? scope.row.user.p_user.name : "--"
              }}</template>
            </el-table-column> -->
            <el-table-column prop="user" label="推荐人">
              <template slot-scope="scope">
                {{scope.row.user && scope.row.user.p_user ? scope.row.user.p_user.name : "--"}}
              </template>
            </el-table-column>
            <el-table-column prop="date" label="订单状态">
              <template slot-scope="scope">
                <!-- {{ scope.row.status - 0 == 1 ? "冻结中" : "可提现" }} -->
                <span v-if="scope.row.status - 0 == 0" style="color:#909399;"
                  >未付款</span
                >
                <span v-if="scope.row.status - 0 == 1" style="color:#7ea4ef;"
                  >待核销</span
                >
                <span v-if="scope.row.status - 0 == 2" style="color:#67c23a;"
                  >已完成</span
                >
                <!-- <span v-else style="color:#67c23a;">可提现</span> -->
              </template>
            </el-table-column>
            <el-table-column prop="date" label="转单操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  :disabled="scope.row.status - 0 < 1"
                  @click="transferSataus(scope.row)"
                  >转单</el-button
                >
              </template>
            </el-table-column>
            <!-- <el-table-column prop="date" label="订单备注"> </el-table-column> -->
          </el-table>
        </div>
        <div v-if="tabIndex">
          <el-table
            :data="tab[tabIndex].data"
            :border="$table('border')"
            :stripe="$table('stripe')"
            key="2"
            :header-cell-style="{ background: $table('bgcolor') }"
          >
            <el-table-column prop="created_at" label="申请时间">
            </el-table-column>
            <el-table-column prop="amount" label="提现金额"> </el-table-column>
            <el-table-column prop="bank.cardtype" label="账户类型">
            </el-table-column>
            <el-table-column prop="bank.card" label="收款账户">
            </el-table-column>
            <el-table-column prop="date" label="提现状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status - 0 == 0" style="color:#909399;"
                  >提现中</span
                >
                <span v-else style="color:#67c23a;">提现成功</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="备注信息">
              <template slot-scope="scope">
                <div v-if="scope.row.remark">
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    :content="scope.row.remark"
                  >
                    <el-button size="mini" slot="reference">查看备注</el-button>
                  </el-popover>
                </div>
                <span v-else style="color:#909399;">无备注...</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          v-if="$pageStatus(tab[tabIndex].total)[0]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tab[tabIndex].page"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="$pageStatus(tab[tabIndex].total)[1]"
          layout="total, prev, pager, next, jumper"
          :total="tab[tabIndex].total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="绑定账户" :visible.sync="dialogVisible" width="500px">
      <div class="wei-edit">
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">支行名称：</div>
          <div class="wei-edit-list-input">
            <!-- <el-input
              v-model="card.bankname"
              placeholder="请输入支行名称"
            ></el-input> -->
            <el-select
              v-model="card.bankname"
              filterable
              style="width:100%"
              placeholder="请输入支行名称"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">银行卡号：</div>
          <div class="wei-edit-list-input">
            <el-input
              v-model="card.card"
              placeholder="请输入银行卡号"
            ></el-input>
          </div>
        </div>
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">开户地区：</div>
          <div class="wei-edit-list-input">
            <el-cascader
              style="width:100%"
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange"
            >
            </el-cascader>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save_card">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="申请提现" :visible.sync="dialogVisible1" width="500px">
      <div class="wei-edit" v-if="bank.bank.card">
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">收款账户：</div>
          <div class="wei-edit-list-input" v-if="bank">
            {{ bank.bank.card }} ({{ bank.bank.cardtype }})
          </div>
        </div>
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">可提现余额：</div>
          <div class="wei-edit-list-input">
            <span style="color:color: #f57665;"
              >￥{{ bank.wallet_amount }}元</span
            >
          </div>
        </div>
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">提现金额：</div>
          <div class="wei-edit-list-input">
            <el-input-number
              v-model="card.num"
              :precision="2"
              :step="0.1"
              controls-position="right"
              :min="100"
              :max="999999999"
            ></el-input-number>
          </div>
        </div>
        <el-alert title="最低提现100元起" type="info"> </el-alert>
        <div class="wei-edit-list">
          <div class="wei-edit-list-title">提现备注：</div>
          <div class="wei-edit-list-input">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="card.remark"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button
          type="primary"
          @click="Cash_out"
          v-if="bank.wallet_amount > 100"
          >确 定</el-button
        >
        <el-button type="primary" disabled v-if="bank.wallet_amount < 100"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 转单、选会员 -->
    <el-dialog
      title="搜索转单会员"
      :visible.sync="dialogVisible2"
      width="1000px"
    >
      <el-input
        style="margin-bottom:10px"
        placeholder="请输入转单会员手机号码"
        v-model="user_key"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchUser"
        ></el-button>
      </el-input>

      <el-table
        :data="transferData"
        :border="$table('border')"
        :stripe="$table('stripe')"
        :header-cell-style="{ background: $table('bgcolor') }"
        style="width: 100%"
      >
        <el-table-column
          prop="nick_name"
          label="选择会员"
          v-if="transferData.length > 1"
        >
          <template slot-scope="scope">
            <el-radio v-model="radio" @change="userCheck" :label="scope.$index"
              >选择会员</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column prop="avatar_url" label="用户头像">
          <template slot-scope="scope">
            <div class="user-img">
              <img
                :src="scope.row.avatar_url"
                :alt="scope.row.nick_name"
                v-if="scope.row.avatar_url"
              />
              <img
                src="../../assets/none.png"
                :alt="scope.row.nick_name"
                v-if="!scope.row.avatar_url"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nick_name" label="微信昵称">
          <template slot-scope="scope">
            {{ scope.row.nick_name || "--" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" label="用户名"> </el-table-column> -->
        <el-table-column prop="phone" label="手机号码"> </el-table-column>
        <el-table-column prop="member_id" label="绑定会员">
          <template slot-scope="scope">
            {{ scope.row.member_id || "未绑定" }}
          </template>
        </el-table-column>
        <el-table-column prop="p_id" label="用户上级">
          <template slot-scope="scope">
            {{ scope.row.p_id || "未绑定上级" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="staff_id" label="操作">
          <template slot-scope="scope">
             <el-button type="danger" @click="selectUser(scope.row)">选择会员</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="transfers">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      tabPosition: "left",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      allData: [
        {
          name: "账户余额",
          num: "0.00",
          img: require("../../assets/none.png"),
          title: "",
        },
        {
          name: "待结算",
          num: "0.00",
          img: require("../../assets/none.png"),
          title: "这个是待核算中的金额",
        },
        {
          name: "提现中",
          num: "0.00",
          img: require("../../assets/none.png"),
          title: "这个是提现中的金额",
        },
      ],
      tab: [
        {
          name: "微店订单",
          href: "/api/app/meiye/wechat_order/store",
          order_type: 0,
          key: "",
          value: "0",
          status: "-1",
          code: "",
          phone: "",
          p_user_id: "",
          page: 1,
          total: 1,
          time: [],
          data1: "",
          data2: "",
          data: [],
        },
        {
          name: "金额日志",
          href: "/api/app/meiye/wallet/wachat_amount_log",
          order_type: 0,
          page: 1,
          total: 1,
          time: [],
          data1: "",
          data2: "",
          data: [],
        },
        {
          name: "提现记录",
          href: "/api/app/meiye/wallet/logs",
          order_type: 0,
          page: 1,
          total: 1,
          time: [],
          data1: "",
          data2: "",
          data: [],
        },
      ],
      activeName: "微店订单",
      tabIndex: 0,
      options: provinceAndCityData,
      selectedOptions: [],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      card: {
        bankname: "",
        card: "",
        province: "",
        city: "",
        num: 100,
        remark: "",
      },
      bank: { bank: { card: "" } },
      options2: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "项目",
        },
        {
          value: 2,
          label: "卡项",
        },
        {
          value: 3,
          label: "促销商品",
        },
        {
          value: 4,
          label: "拼团",
        },
        {
          value: 5,
          label: "秒杀",
        },
        {
          value: 6,
          label: "原价购买拼团商品",
        },
      ],
      options3: [
        {
          value: 1,
          label: "人民银行",
        },
        {
          value: 2,
          label: "工商银行",
        },
        {
          value: 3,
          label: "农业银行",
        },
        {
          value: 4,
          label: "中国银行",
        },
        {
          value: 5,
          label: "建设银行",
        },
        {
          value: 6,
          label: "交通银行",
        },
        {
          value: 7,
          label: "招商银行",
        },
        {
          value: 8,
          label: "浦发银行",
        },
        {
          value: 9,
          label: "民生银行",
        },
        {
          value: 10,
          label: "兴业银行",
        },
        {
          value: 11,
          label: "深发展银行",
        },
        {
          value: 12,
          label: "华夏银行",
        },
        {
          value: 13,
          label: "光大银行",
        },
        {
          value: 14,
          label: "广发银行",
        },
        {
          value: 15,
          label: "中信银行",
        },
      ],
      tabUserData: {},
      transferData: [],
      user_key: "",
      user_data: {},
      radio: "-1",
    };
  },
  methods: {
    /* 显示转单窗口 */
    transferSataus(obj) {
      console.log(obj);
      this.dialogVisible2 = true;
      this.tabUserData = obj;
      this.user_key = "";
      this.transferData = [];
    },
    /* 搜索会员 */
    searchUser() {
      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(
          this.user_key
        )
      ) {
        this.$message.error("请输入用户手机号码");
        return false;
      }
      this.$axios({
        href: "/api/app/meiye/wechat/user_store",
        data: { phone: this.user_key },
      }).then((res) => {
        console.log('searchUserRes', res);
        this.transferData = res.data.data.items;
      });
    },
    /* 多个会员选中 */
    userCheck(value) {
      // console.log(value);
      this.radio = value;
    },
    /* 选中会员 */
    transfers() {
      if (!this.transferData.length) {
        this.$message.error("请先搜索会员~");
        return false;
      }
      /* 多个会员账号 */

      if (this.transferData.length > 1 && this.radio == "-1") {
        this.$message.error("请选择会员");
        return false;
      }

      let userIndes = this.transferData.length > 1 ? this.radio : 0;
      // console.log(userIndes);

      this.$confirm(
        "此操作将转单给【" +
          this.transferData[userIndes].name +
          "】, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/wechat_order/to_user",
            data: {
              order_id: this.tabUserData.id,
              user_wechat_id: this.transferData[userIndes].id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: `转单给【${this.transferData[userIndes].name}】成功~`,
            });
            this.get_order();
            this.dialogVisible2 = false;
            console.log(11111);
          });
        })
        .catch(() => {});
      this.transferData;
    },
    /* 获取地区 */
    handleChange(value) {
      // console.log(CodeToText[value[0]]);
      this.card.province = CodeToText[value[0]];
      this.card.city = CodeToText[value[1]];
    },
    /* 绑定账户 */
    save_card() {
      if (!this.card.bankname) {
        this.$message.error("请输入开户支行");
        return false;
      }
      if (!/^[1-9]\d{9,29}$/.test(this.card.card)) {
        this.$message.error("请输入正确的银行卡号码");
        return false;
      }
      if (!this.card.province || !this.card.city) {
        this.$message.error("请输入开户地区");
        return false;
      }

      this.$axios({
        href: "/api/app/meiye/shopbank/check",
        data: this.card,
      }).then(() => {
        this.$axios({
          href: "/api/app/meiye/shopbank/add",
          data: this.card,
        }).then((res) => {
          this.$message({
            message: "添加成功~",
            type: "success",
          });
          this.getList();
          this.dialogVisible = false;
          console.log(res);
        });
      });
    },
    /* 提现 */
    Cash_out() {
      this.$axios({
        href: "/api/app/meiye/shop/wallet",
      }).then((res) => {
        res.data.data.bank = res.data.data.bank
          ? res.data.data.bank
          : { card: "" };
        this.bank = res.data.data;
        this.$nums(res.data.data.wallet_amount, "#all-head-list-num-0", true);
        this.$nums(res.data.data.wallet_dj, "#all-head-list-num-1", true);
        this.$nums(res.data.data.wallet_apply, "#all-head-list-num-2", true);
        if (res.data.data.wallet_amount <= this.card.num) {
          this.$message({
            message: "提现金额不能大于可提现金额",
            type: "warning",
          });
          return false;
        }

        this.$axios({
          href: "/api/app/meiye/wallet/apply",
          data: { amount: this.card.num, remark: this.card.remark },
        }).then(() => {
          this.getList();
          this.$message({
            message: "恭喜你，提交申请成功~",
            type: "success",
          });
        });
      });

      this.dialogVisible1 = false;
      this.$message({
        message: "提现成功~",
        type: "success",
      });
    },
    /* 数字动画 */
    nums(obj, dom) {
      let num = obj - 0; //总数
      // num = (num < 0) ? (num * -1) : num;
      let step = num / (1000 / 50); //每30ms增加的数值
      let count = 0; //计数器
      let timer = null; //计时器
      let initial = 0;
      let text = document.querySelector(dom); //dom
      if (!text) {
        return "0.00";
      }
      if (num < 1) {
        text.innerHTML = obj;
        return false;
      }
      clearInterval(timer);
      timer = setInterval(() => {
        count += step;
        if (count >= num) {
          clearInterval(timer);
          count = num;
        }
        //t未发生改变的话就直接返回
        let t = Math.floor(count);
        if (t == initial) return;
        initial = t;
        text.innerHTML = initial.toFixed(2);
      }, 50);
    },
    /* 搜索 */
    search() {
      // console.log(this.value2);
      if (!this.tab[this.tabIndex].time) {
        this.tab[this.tabIndex].time = [];
      }
      if (
        !this.tab[this.tabIndex].time.length &&
        this.tab[this.tabIndex].time > 1
      ) {
        this.$message({
          message: "请选择日期",
          type: "warning",
        });
        return false;
      }
      this.tab[this.tabIndex].page = 1;
      this.getList();
    },
    /* 日期清除 */
    clearDate(val) {
      if (!val) {
        this.tab[this.tabIndex].time = [];
        this.tab[this.tabIndex].data1 = "";
        this.tab[this.tabIndex].data2 = "";
        this.tab[this.tabIndex].page = 1;
        this.getList();
      }
      console.log(val);
      this.tab[this.tabIndex].data1 = this.tab[this.tabIndex].time[0];
      this.tab[this.tabIndex].data2 = this.tab[this.tabIndex].time[1];
    },
    /* 获取余额 */
    getList() {
      this.$axios({
        href: "/api/app/meiye/shop/wallet",
      }).then((res) => {
        res.data.data.bank = res.data.data.bank
          ? res.data.data.bank
          : { card: "" };
        this.bank = res.data.data;
        console.log(res.data.data);
        this.$nums(res.data.data.wallet_amount, "#all-head-list-num-0", true);
        this.$nums(res.data.data.wallet_dj, "#all-head-list-num-1", true);
        this.$nums(res.data.data.wallet_apply, "#all-head-list-num-2", true);
      });
    },
    /* 获取订单 */
    get_order() {
      let d = JSON.parse(JSON.stringify(this.tab[this.tabIndex]));
      d.order_type = d.order_type ? d.order_type : null;
      d.status = d.status - 0 >= 0 ? d.status : null;
      if (d.value == 1) {
        d.code = d.key;
        d.phone = null;
        d.p_user_id = null;
      }
      if (d.value == 2) {
        d.code = null;
        d.phone = d.key;
        d.p_user_id = null;
      }
      if (d.value == 3) {
        d.code = null;
        d.phone = null;
        d.p_user_id = d.key;
      }
      if (!d.key && d.value > 0) {
        this.$message.error("关键字不能为空");
        return false;
      }

      this.$axios({
        href: this.tab[this.tabIndex].href,
        data: d,
      }).then((res) => {
        this.tab[this.tabIndex].data = res.data.data.items;
        this.tab[this.tabIndex].total = res.data.data.total;
        console.log(this.tab[this.tabIndex].data);
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.tab[this.tabIndex].page = val;
      this.get_order();
    },
    handleCurrentChange(val) {
      this.tab[this.tabIndex].page = val;
      this.get_order();
      // console.log(`当前页: ${val}`);
    },
    handleClick(tab) {
      this.tabIndex = tab.index - 0;
      this.get_order();
    },
  },
  mounted() {
    this.getList();
    this.get_order();
  },
};
</script>

<style lang="scss" scoped>
.wei {
  width: 100%;
  //   height: 100%;
  min-height: 100%;
  background: #fff;
  &-head {
    width: 100%;
    height: 80px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #eee;
    margin-bottom: 35px;
    position: relative;
    &-btn {
      position: absolute;
      top: 0;
      right: 15px;
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    // background: red;
  }
  &-box {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  &-edit {
    width: 100%;
    height: 100%;
    &-list {
      width: 100%;
      height: 60px;
      // background: red;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-title {
        color: #555555;
        width: 20%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-input {
        color: #000;
        font-weight: Bold;
        width: 80%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
.all {
  width: 100%;
  height: 100%;
  &-head {
    width: 100%;
    height: 150px;
    display: flex;
    overflow-x: auto;

    // background: red;
    &-list {
      // float: left;
      width: 50%;
      border-radius: 6.5px 6.5px 0px 0px;
      min-width: 220px;
      height: 120px;
      padding: 0 10px;
      margin: 10px 1%;
      box-sizing: border-box;
      //   border-radius: 5px;
      cursor: pointer;
      background: #fff;
      background: #fff;
      border: 1px solid #eee;
      transition: all 0.5s;
      font-size: 13px;
      position: relative;
      display: flex;
      &-l {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-r {
        width: 70%;
        height: 100%;
        margin-left: 10px;
        position: relative;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      &-title {
        width: 100%;
        height: 40%;
        font-size: 16px;
        color: #8088a8;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }
      &-num {
        width: 100%;
        height: 60%;
        font-size: 36px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-btn {
        position: absolute;
        top: 10px;
        right: 0px;
        width: 80px;
        // height: 6px;
        // background: red;
      }
      // &-num:last-child {
      //   color:rgb(242, 95, 74);
      // }
      &-img {
        // position: absolute;
        // top: 10px;
        // right: 10px;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        // background: red;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    &-list:hover {
      //   margin-top: -1px;
      border-radius: 5px;
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #b3d8ff;
    }
  }
}
#alls,
#alls1 {
  margin: 20px 0;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  // background: red;
}
.alls {
  // margin: 20px 0;
  width: 100%;
  height: 560px;
  &-head {
    width: 100%;
    height: 130px;
    transition: all 0.5s;
    // display: flex;
    &-l {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-r {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &-list {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          display: inline-block;
          width: 100px;
          height: 100%;
          font-size: 15px;
          line-height: 60px;
        }
      }
      //   background: red;
    }
  }
}
.other {
  width: 100%;
  height: 100%;
  &-head {
    width: 100%;
    height: 60px;
    // background: red;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-name {
      width: 50%;
      height: 100%;
      padding: 0 10px;
      font-size: 20px;
      font-weight: 800;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-tab {
      width: 50%;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &-box {
    width: 100%;
    height: 500px;
    display: flex;
    &-l {
      width: 50%;
      height: 100%;
      margin-right: 10px;
    }
    &-r {
      width: 50%;
      height: 100%;
    }
  }
}
.tubiao {
  width: 100%;
  height: 100%;
}
.edit {
  width: 100%;
  height: 100%;
  display: flex;
  &-list {
    width: 100%;
    height: 100%;
    &-item {
      width: 100%;
      height: 50px;
      display: flex;
      // background: red;
      &-lable {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-input {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.user-img {
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>

<style>
.wei .el-tabs__nav-wrap::after {
  width: 0%;
}
.alls-head-r-list .el-input-group__prepend {
  width: 90px;
}
</style>
